import React from 'react';
import ChallengePage from './pages/ChallengePage';


function App() {
  return (
    <div className="App">
      <ChallengePage />
    </div>
  );
}

export default App;
